import { render, staticRenderFns } from "./Statistics.vue?vue&type=template&id=9265452a&scoped=true&"
import script from "./Statistics.vue?vue&type=script&lang=js&"
export * from "./Statistics.vue?vue&type=script&lang=js&"
import style0 from "./Statistics.vue?vue&type=style&index=0&id=9265452a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9265452a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VImg,VRow,VSpacer})
